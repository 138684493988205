export default {
    data() {
        return {
            page: 1,
            pageSize: Number(localStorage.getItem('pageSize') || 20),
            totalPages: 0,
            totalElements: 0,
            tableData: [],
            sort: {},
            sortStr: 'createdAt,desc',
            tableHeight: 200,
            fetchingData: false
        };
    },
    created() {
        this.page = Number(this.$route.query.page) || 1;
        if (this.$route.query.sort) {
            let sort = {};
            for (let s of this.$route.query.sort.split(';')) {
                if (s) {
                    let [prop, order] = s.split(',');
                    sort[prop] = order;
                }
            }
            this.sort = sort;
        }
        this.getData();
        this.getPayAmt();
    },
    mounted() {
        this.tableHeight = document.querySelector('.el-table').getBoundingClientRect().height;
    },
    methods: {
        getData() {
            let query = {
                del: false,
                ...this.$route.query
            };
            delete query.sort;
            delete query.page;
            delete query.size;
            let data = {
                page: this.page - 1,
                size: this.pageSize,
                sort: this.sortStr,
                query: query
            };
            if (this.beforeGetData) {
                let mergeData = this.beforeGetData();
                if (mergeData) {
                    data = {
                        ...data,
                        ...mergeData
                    };
                }
            }
            data.query.projectId = this.$store.state.projectId;
            this.fetchingData = true;
            this.$http
                .post(this.url, data, {
                    body: 'json'
                })
                .then(res => {
                    this.fetchingData = false;
                    this.tableData = res.content ? res.content:res.info ;
                    this.totalPages = res.totalPages;
                    this.totalElements = Number(res.totalElements?res.totalElements:res.total);
                    if (this.afterGetData) {
                        this.afterGetData(res);
                    }
                })
                .catch(e => {
                    this.fetchingData = false;
                    console.log(e);
                    this.$message.error(e.error);
                });
        },
        initData() {
            this.page = 1;
            this.getData();
        },
        changeSort(prop, order) {
            let newOrder = '';
            if (order) {
                if (this.sort[prop] !== order) {
                    newOrder = order;
                } else {
                    newOrder = '';
                }
            } else {
                if (this.sort[prop] === 'asc') {
                    newOrder = 'desc';
                } else if (this.sort[prop] === 'desc') {
                    newOrder = '';
                } else {
                    newOrder = 'asc';
                }
            }
            this.sort = {
                ...this.sort,
                [prop]: newOrder
            };
        },
        getPayAmt() {
            // this.searchingMinter = true;
            let query = {
                del: false,
                ...this.$route.query
            };
            delete query.sort;
            delete query.page;
            delete query.size;
            let data = {
                page: this.page - 1,
                size: this.pageSize,
                sort: this.sortStr,
                query: query
            };
            if (this.beforeGetData) {
                let mergeData = this.beforeGetData();
                if (mergeData) {
                    data = {
                        ...data,
                        ...mergeData
                    };
                }
            }
            data.query.projectId = this.$store.state.projectId;
            this.$http.post('/order/totalAmount', data, {
                body: 'json'
            }).then(res => {
                console.log(res, 'res');
                this.totalAmount = res.totalAmount;
                // this.searchingMinter = false;
            });
        },
        onSortChange(e) {
            this.sort = e;
            this.getData();
        },
        onSizeChange(e) {
            localStorage.setItem('pageSize', e);
            this.page = 1;
            this.pageSize = e;
            this.getData();
        },
        onCurrentChange(e) {
            this.$router
                .replace({
                    query: {
                        ...this.$router.query,
                        page: e
                    }
                })
                .catch(_ => {});
            this.page = e;
            this.getData();
        },
        adjustColumnWidth() {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            let measureTxt = text => {
                return parseInt(ctx.measureText(text).width);
            };
            let rowWidth = [];
            let headWidth = [];
            document.querySelectorAll('.el-table__body-wrapper .table-row').forEach(row => {
                row.querySelectorAll('.table-cell').forEach((cell, index) => {
                    let children = cell.querySelector('.cell').children;
                    if (children && children.length) {
                        let width = 0;
                        console.log(children);
                        for (let child of children) {
                            width +=
                                child.clientWidth +
                                parseInt(window.getComputedStyle(child).marginLeft.replace('px', '')) +
                                parseInt(window.getComputedStyle(child).marginRight.replace('px', ''));
                        }
                        rowWidth[index] = width;
                    } else {
                        console.log(cell.querySelector('.cell').innerHTML);
                        rowWidth[index] = measureTxt(cell.querySelector('.cell').innerHTML);
                    }
                });
            });
            this.$refs.table.columns.forEach(({
                label
            }, index) => {
                headWidth[index] = measureTxt(label) + 50;
            });
            console.log(rowWidth);
            console.log(headWidth);
        }
    },
    watch: {
        sort() {
            let sortStr = [];
            for (let [key, value] of Object.entries(this.sort)) {
                if (value) {
                    sortStr.push(key + ',' + value);
                }
            }
            sortStr = sortStr.join(';');
            this.$router
                .replace({
                    query: {
                        ...this.$route.query,
                        sort: sortStr
                    }
                })
                .catch(_ => {});
            this.sortStr = sortStr;
            this.getData();
        },
        '$store.state.projectId'() {
            this.search = '';
            this.page = 1;
            this.getData();
        }
    }
};
