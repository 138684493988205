<template>
  <div class="list-view">
    <page-title>
      <el-button
        @click="addRow"
        type="primary"
        icon="el-icon-plus"
        :disabled="fetchingData || downloading"
        class="filter-item"
      >
        新增
      </el-button>
    </page-title>
<!--    <div class="filters-container">-->
<!--      <el-input-->
<!--        placeholder="搜索..."-->
<!--        v-model="search"-->
<!--        clearable-->
<!--        class="filter-item search"-->
<!--        @keyup.enter.native="initData"-->
<!--      >-->
<!--        <el-button-->
<!--          @click="initData"-->
<!--          slot="append"-->
<!--          icon="el-icon-search"-->
<!--        > </el-button>-->
<!--      </el-input>-->
<!--    </div>-->
    <el-table
      :data="tableData"
      row-key="id"
      ref="table"
      header-row-class-name="table-header-row"
      header-cell-class-name="table-header-cell"
      row-class-name="table-row"
      cell-class-name="table-cell"
      :height="tableHeight"
      v-loading="fetchingData"
    >
      <el-table-column
        v-if="multipleMode"
        align="center"
        type="selection"
        width="50"
      > </el-table-column>
      <el-table-column
        prop="id"
        label="ID"
        width="100"
      > </el-table-column>
      <el-table-column
        prop="name"
        label="分类名称"
        show-overflow-tooltip
      > </el-table-column>
              <el-table-column
        label="操作"
        align="center"
        fixed="right"
        width="400"
      >
        <template slot-scope="{ row }">
            <el-button
                v-if="row.parentId == 0"
                @click="addChild(row)"
                type="primary"
                size="mini"
                plain
            >新增二级</el-button>
          <el-button
            @click="editRow(row)"
            type="primary"
            size="mini"
            plain
          >查看</el-button>
            <el-button
            @click="delRow(row)"
            type="primary"
            size="mini"
            plain
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--   二级分类新增   -->
    <el-dialog title="新增二级分类" :visible.sync="childDialog">
        <el-form
            :model="formData"
            :rules="rules"
            ref="form"
            label-width="140px"
            label-position="right"
            size="small"
            style="max-width: 800px">
            <el-form-item
                v-show="flase"
                label="项目"
                prop="projectId"
            >
                <project-select v-model="formData.projectId">
                </project-select>
            </el-form-item>
            <el-form-item
                v-if="!canEdit"
                prop="id"
                label="ID"
            >
                <el-input
                    v-model="formData.id"
                    :disabled="!canEdit"
                ></el-input>
            </el-form-item>
            <el-form-item
                prop="name"
                label="分类名称"
            >
                <el-input v-model="formData.name"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="childDialog = false">取 消</el-button>
            <el-button type="primary" @click="onSave">保存</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';
import SortableHeader from '../components/SortableHeader.vue';

export default {
    components: { SortableHeader },
    name: 'CollectionList',
    data() {
        return {
            childDialog: false,
            multipleMode: false,
            search: '',
            url: '/noticeCategory/all',
            downloading: false,
            sort: { sort: 'desc' },
            sortStr: 'sort,desc',
            createdAt: '',
            minterId: '',
            tableHeight: 200,
            params: {
                query: {
                    del: false,
                    createAt: '',
                    minterId: '',
                    projectId: ''
                },
                search: '',
                sort: 'createdAt,desc'
            },
            tableData: [],
            fetchingData: false,
            formData: {
                projectId: this.$store.state.projectId,
                id: '',
                parentId: ''
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入分类名称',
                        trigger: 'blur'
                    }
                ]
            }
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        },
        canEdit() {
            return !!!this.$route.query.id;
        }
    },
    created() {
        this.getList()
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData };

            this.saving = true;
            this.$http
                .post('/noticeCategory/save', data, {
                    body: 'json'
                })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.getList()
                    this.childDialog = false
                })
                .catch(e => {
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        addChild(item) {
            this.formData.parentId = item.id
            this.childDialog = true
        },
        getList() {
            this.$http.post('noticeCategory/all', this.params, {
                body: 'json'
            }).then(res=>{
                this.tableData = res
            })
        },
        typeFormatter(row, column, cellValue, index) {
            let selectedOption = this.typeOptions.find(i => i.value === cellValue);
            if (selectedOption) {
                return selectedOption.label;
            }
            return '';
        },
        sourceFormatter(row, column, cellValue, index) {
            let selectedOption = this.sourceOptions.find(i => i.value === cellValue);
            if (selectedOption) {
                return selectedOption.label;
            }
            return '';
        },
        beforeGetData() {
            return {
                sort: 'createdAt,desc',
                search: this.search,
                query: { del: false, createdAt: this.createdAt, minterId: this.minterId }
            };
        },

        addRow() {
            this.$router.push({
                path: '/noticeCategoryEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow(row) {
            this.$router.push({
                path: '/noticeCategoryEdit',
                query: {
                    id: row.id
                }
            });
        },
        delRow(row) {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/noticeCategory/del/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        },
        operation1() {
            this.$notify({
                title: '提示',
                message: this.selection
            });
        },
        operation2() {
            this.$message('操作2');
        },
    }
};
</script>
<style lang="less" scoped>
</style>
