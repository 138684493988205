<template>
  <div class="edit-view">
    <page-title>
      <el-button
        @click="$router.go(-1)"
        :disabled="saving"
      >取消</el-button>
      <!-- <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button> -->
      <el-button
        @click="onSave"
        :loading="saving"
        type="primary"
      >保存</el-button>
    </page-title>
    <div class="edit-view__content-wrapper">
      <div class="edit-view__content-section">
        <el-form
          :model="formData"
          :rules="rules"
          ref="form"
          label-width="140px"
          label-position="right"
          size="small"
          style="max-width: 800px"
        >
          <el-form-item
            v-show="flase"
            label="项目"
            prop="projectId"
          >
            <project-select v-model="formData.projectId">
            </project-select>
          </el-form-item>
          <el-form-item
            v-if="!canEdit"
            prop="id"
            label="ID"
          >
            <el-input
              v-model="formData.id"
              :disabled="!canEdit"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="name"
            label="分类名称"
          >
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
            <el-form-item prop="pic" label="分类图片">
                <single-upload v-model="formData.pic"></single-upload>
            </el-form-item>
            <el-form-item
                prop="total"
                label="分类排序"
            >
                <el-input v-model="formData.total"></el-input>
            </el-form-item>
          <el-form-item class="form-submit">
            <el-button
              @click="onSave"
              :loading="saving"
              type="primary"
            > 保存 </el-button>
            <!-- <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id">
                            删除
                        </el-button> -->
            <el-button
              @click="$router.go(-1)"
              :disabled="saving"
            >取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import resolveUrl from 'resolve-url';
export default {
    name: 'CollectionClassEdit',
    created() {
        if(this.$route.query.id){
            this.$http
                .get('collectionCategory/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
        if(this.$route.query.parentId) {
            this.formData.parentId = this.$route.query.parentId;
        }

    },
    computed: {
        canEdit() {
            return !!!this.$route.query.id;
        }
    },
    data() {
        return {
            saving: false,
            formData: {
                projectId: this.$store.state.projectId,
                id: '',
                // parentId: this.formData.parentId || 0
                parentId: 0
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入分类名称',
                        trigger: 'blur'
                    }
                ],
                pic: [
                    {
                        required: true,
                        message: '请输入分类图片',
                        trigger: 'blur'
                    }
                ],
                total: [
                    {
                        required: true,
                        message: '请输入分类总数',
                        trigger: 'blur'
                    }
                ]
            }
        };
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData };

            this.saving = true;
            this.$http
                .post('/collectionCategory/save', data, {
                    body: 'json'
                })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/collectionCategory/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-switch__label--left {
    width: 50px;
    text-align: right;
}
.number-percent {
    display: flex;
    align-items: center;
    .percent {
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        width: 30px;
        text-align: center;
        line-height: 30px;
        color: @text2;
        font-size: 13px;
    }
}
.tip {
    font-size: 12px;
    color: @text3;
    margin-top: 5px;
}

.img-view-wrap {
    display: flex;

    .img-view-li {
        margin-right: 20px;
    }
}
</style>
